<template>
  <div class="user_maindate">

    <section>
      <Table :loading="loading" border ref="selection" :columns="columns" :data="Tabledata">
        <template slot-scope="{ row }" slot="details">
          <!-- <Button type="primary" size="small" @click="$router.push({name:'interview_manage_detail',params:{id:row.e_resumeId}})">查看</Button> -->
          <Button type="primary" size="small" @click="$router.push({name:'interview_manage_detail',query:{id:row.e_resumeId}})">查看</Button>

        </template>
      </Table>
    </section>

    <div class="footer">
      <Page :total="pagecount" show-sizer show-total @on-change="getdata_page" @on-page-size-change="getdata_pagesize" />
    </div>

    <div class="footer">
      <Button type="primary" size="large" @click="exportData()">
        <Icon type="ios-download-outline"></Icon>批量导出
      </Button>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      page: 1,
      size: 10,
      pagecount: 0,
      Tabledata: [],
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '姓名',
          key: 'name',
          render: (h, params) => {
            return h(
              "span",
              params.row.personal ? params.row.personal.name : ''
            )
          }
        },
        {
          title: '性别',
          key: 'sex',
          render: (h, params) => {
            return h(
              "span",
              params.row.personal ? (params.row.personal.sex == 1 ? '男' : '女') : ''
            )
          }
        },
        {
          title: '工作经验',
          key: 'workYear',
        },
        {
          title: '年龄',
          key: 'age',
        },
        {
          title: '学历',
          key: 'education',
          render: (h, params) => {
            return h(
              "span",
              params.row.personal ? params.row.personal.education : ''
            )
          }
        },
        {
          title: '手机号',
          key: 'phone',
          render: (h, params) => {
            return h(
              "span",
              params.row.personal ? params.row.personal.phone : ''
            )
          }
        },
        {
          title: '投递岗位',
          key: 'positionName',
        },
        {
          title: '面试时间',
          key: 'date',
        },
        {
          title: '操作',
          slot: 'details',
          width: 140,
          align: 'center',
        }


      ],

    }
  },
  methods: {

    get_data() {
      this.loading = true
      this.app("enterpriseOperators", "getMyInterViews", {
        mobile: this.mobile,
        page: this.page,
        size: this.size
      }).then(res => {
        console.log(res);
        this.loading = false
        this.pagecount = res.pageSize
        this.Tabledata = res.data

        res.data.forEach(row => {
          if (row.personal) {

            // 生日
            var data = row.personal.birthDate;
            // console.log(data);
            if (data == undefined || data == null) {

            } else {
              var birthYear = data[0] + data[1] + data[2] + data[3];
              var y = new Date().getFullYear();
            }

            // 开始工作时间
            var dates = row.personal.workedDate;
            if (dates == undefined || dates == null) {

            } else {
              var workedDate = dates[0] + dates[1] + dates[2] + dates[3];
            }

            var age = y - birthYear + "岁";
            var workYear = y - workedDate + "年";

            row.age = age;
            row.workYear = workYear;
          }

        });
      })
    },



    // 分页页码
    getdata_page(page) {
      this.page = page;
      this.get_data()
    },

    // 分页每页条数
    getdata_pagesize(size) {
      this.size = size;
      this.get_data()
    },

    // 导出数据
    exportData() {
      this.$refs.selection.exportCsv({
        filename: '授权用户信息',
        original: false,
        columns: this.columns,
        data: this.Tabledata
      });
    }

  },
  created() {

    this.mobile = localStorage.getItem("firm_mobile");
    this.app("enterpriseOperators", "getEmployeeMassage", {
      mobile: this.mobile,
    }).then(res => {
      console.log(res);
      if (res.data.enterpriseId) {
        // 保存个人id，职位id到本地
        // localStorage.setItem("emploee_id", res.result.data._id);
        // localStorage.setItem("enterprise_id", res.result.data.enterpriseId);
        // enterprieseId = res.result.data.enterpriseId;

        this.get_data()

      } else {
        this.$Modal.confirm({
          title: '提示',
          content: '企业信息未完善，请先完善信息',
          onOk: () => {
            // this.$router.push({name:''})

          }
        });
      }
    })
  }


}
</script>

<style lang="less" scoped>
.user_maindate {
  color: #000000;
  font-size: 18px;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    margin-bottom: 16px;
    .left {
      display: flex;
      align-items: center;
      .top_item {
        display: flex;
        align-content: center;
        margin-right: 48px;
        p {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 18px;
    height: 35px;
  }
}
</style>